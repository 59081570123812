document.addEventListener("DOMContentLoaded", function () {
    const sliders = document.querySelectorAll(".slider-container");
  
    sliders.forEach((sliderContainer) => {
      const slider = sliderContainer.querySelector(".slider");
      const slides = slider.querySelectorAll(".slide");
      const prevButton = sliderContainer.querySelector(".prev");
      const nextButton = sliderContainer.querySelector(".next");
      const totalSlides = slides.length;
  
      let currentIndex = 0;
      let autoSlideInterval;
  
      const updateSliderPosition = () => {
        slider.style.transition = "transform 0.5s ease";
        slider.style.transform = `translateX(-${currentIndex * 100}%)`;
      };
  
      const showNextSlide = () => {
        currentIndex = (currentIndex + 1) % totalSlides;
        updateSliderPosition();
        resetAutoSlide();
      };
  
      const showPrevSlide = () => {
        currentIndex = (currentIndex - 1 + totalSlides) % totalSlides;
        updateSliderPosition();
        resetAutoSlide();
      };
  
      const startAutoSlide = () => {
        autoSlideInterval = setInterval(showNextSlide, 5000); // 5 seconds
      };
  
      const stopAutoSlide = () => {
        clearInterval(autoSlideInterval);
      };
  
      const resetAutoSlide = () => {
        stopAutoSlide();
        startAutoSlide();
      };
  
      // Attach click events for navigation buttons
      nextButton.addEventListener("click", () => {
        showNextSlide();
        resetAutoSlide();
      });
  
      prevButton.addEventListener("click", () => {
        showPrevSlide();
        resetAutoSlide();
      });
  
      // Initialize slider
      updateSliderPosition();
      startAutoSlide();
    });
  });
  